<template>
  <!-- Table Container Card -->
  <b-card
    v-if="allGameCharities.length>0"
    no-body
  >
    <!-- Charity -->
    <div class="d-flex">
      <h4 class="mb-2 ml-2">
        {{ $t('Charity') }}
      </h4>
      <feather-icon
        v-if="canEditCompetition"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer ml-1"
        @click="$router.push({ name: 'charity-list-id', params: { id: encryptId }})"
      />
    </div>
    <div>
      <section id="feather-icons">
        <div
          id="icons-container"
          class="d-flex flex-wrap"
        >
          <b-card
            v-for="charity in allGameCharities"
            :key="charity.ID"
            v-b-tooltip.hover.top="charity.name"
            class="icon-card cursor-pointer text-center mb-2 mx-50"
          >
            <div class="icon-wrapper">
              <b-avatar
                rounded
                size="95"
                variant="light-info"
              >
                <b-img
                  :src="charity.charity.logourl"
                  alt="avatar img"
                  width="90px"
                  height="90px"
                /></b-avatar>
            </div>
          </b-card>
        </div>
      </section>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip, BImg,
  BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    canEditCompetition: {
      type: Boolean,
      default: () => true,
    },
    encryptId: {
      type: String,
      default: () => 0,
      required: true,
    },
  },
  computed: {
    ...mapState('game', ['allGameCharities']),
  },
  mounted() {
    this.fetchAllGameCharities(this.encryptId)
  },
  methods: {
    ...mapActions('game', ['fetchAllGameCharities']),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
